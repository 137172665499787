<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item class="px-2">
        <v-btn icon>
          <v-icon class="icon-menu" @click="drawer = !drawer"
            >mdi-menu-open</v-icon
          >
        </v-btn>
      </v-list-item>

      <!-- <v-list>
				<v-list-item
					v-for="item in items"
					:key="item.title"
					:to="item.to"
					v-model="pageSelect"
					@click="pageSelect = item.title;togglemenu(item.title)"
				>
					<v-list-item-icon>
						<v-icon class="icon-menu abcd">{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="icon-menu">
							{{ item.title }}
							<v-list-item
								v-for="item1 in item.child"
								:key="item1.title"
								:to="item.to"
								v-model="pageSelect"
								style="margin-top: 16px;"
								class="menu-close"
								:class="{ 'menu-open': item1.active }">
								<v-list-item-content>
										<v-list-item-title class="icon-menu">
											<div>
											{{ item1.title }}
											</div>
										</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list> -->
      <v-list>
        <v-list-group
          no-action
          v-for="navLink in items"
          :key="navLink.title"
          :class="navLink.child ? '' : 'no-child'"
        >
          <v-list-item slot="activator" :to="navLink.to">
            <v-list-item-icon>
              <v-icon class="icon-menu abcd">{{ navLink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="icon-menu">{{
              navLink.title
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="sub in navLink.child"
            :key="sub.title"
            :to="sub.to"
          >
            <v-list-item-title class="icon-menu">{{
              sub.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-show="!drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{ pageSelect }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>

      <BadgeCustom fullName="" signName="B" class="avata-header" />
    </v-app-bar>
  </div>
</template>

<script>
import BadgeCustom from "../components/Badge/BadgeCustom";

export default {
  name: "PageHeader",

  components: {
    BadgeCustom,
  },

  data: () => ({
    drawer: null,
    pageSelect: "Home",
    items: [
      { title: "Dashboard", icon: "mdi-home-city", to: "/calendar" },
      {
        title: "Calendar",
        icon: "mdi-calendar-blank-outline",
        to: "/calendar",
      },
      {
        title: "Sales",
        icon: "mdi-account-group-outline",
        child: [
          { title: "Daily Sales", icon: "mdi-home-city", to: "/daily-sales" },
          {
            title: "Appointments",
            icon: "mdi-home-city",
            to: "/appointment-list",
          },
          {
            title: "Sales history",
            icon: "mdi-home-city",
            to: "/sales-history",
          },
          { title: "Products", icon: "mdi-home-city", to: "/products" },
          { title: "Services", icon: "mdi-home-city", to: "/services" },
          { title: "Vouchers", icon: "mdi-home-city", to: "/vouchers" },
          { title: "Memberships", icon: "mdi-home-city", to: "/memberships" },
        ],
      },
      // {
      // 	title: "Vouchers",
      // 	icon: "mdi-ticket-percent-outline",
      // 	to: "/vouchers",
      // },
      { title: "Clients", icon: "mdi-account", to: "/clients" },
      // { title: "Staff", icon: "mdi-briefcase-variant-outline", to: "/staff" },
      // { title: "Services", icon: "mdi-account-group-outline", to: "/services" },
      {
        title: "Inventory",
        icon: "mdi-account-group-outline",
        child: [
          { title: "Product List", icon: "mdi-home-city", to: "/products" },
          { title: "Stocktakes", icon: "mdi-home-city", to: "/stocktakes" },
          {
            title: "Sales history",
            icon: "mdi-home-city",
            to: "/sales-history",
          },
          { title: "Purchase Order", icon: "mdi-home-city", to: "/purchase" },
          { title: "Supplier", icon: "mdi-home-city", to: "/supplier" },
        ],
      },
      // {
      // 	title: "Analytics",
      // 	icon: "mdi-account-group-outline",
      // 	to: "/analytics",
      // },
      // { title: "Setup", icon: "mdi-cog-outline", to: "/setup" },
    ],
  }),

  watch: {
    $route() {
      this.updatePageHeader();
    },
  },

  mounted() {
    this.updatePageHeader();
  },

  methods: {
    updatePageHeader() {
      this.pageSelect = this.$route.name;
    },
  },
};
</script>

<style scoped>
#main .v-navigation-drawer {
  background-color: #1a263a;
  color: white;
}
.icon-menu {
  color: white !important;
}
#main .v-toolbar__title {
  font-weight: bold;
}
.avata-header {
  margin-top: 18px;
}
.v-app-bar {
  box-shadow: none;
}
.v-toolbar__content {
  border-bottom: 1px solid #e1e5e9;
}
.sidebar-menu-title {
  font-size: 20px;
}

.menu-close {
  display: none;
}
.menu-open {
  display: block;
}

.v-app-bar.v-app-bar--fixed {
  z-index: 99 !important;
}
</style>
